/* import __COLOCATED_TEMPLATE__ from './plans.hbs'; */
import { action, set, setProperties } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { decamelize } from '@ember/string';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer, LottiePlayer, Spinner, ToggleButton } from '@repo/design-system-kit';
import { PricePlanContainerSkeleton, PricePlansContainerBridge } from '@repo/domain-kit/pricing';
import { all, dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { Addons } from 'qonto/constants/addons';
import { apiBaseURL, apiNamespace } from 'qonto/constants/hosts';
import { PRICE_PLAN_FEATURES, PRICE_PLANT_TRAITS } from 'qonto/constants/price-plan';
import { SUBSCRIPTION_RECURRENCES, TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';
import { codeToName } from 'qonto/models/subscription-product';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsSubscriptionChangePlansComponent extends Component {
  lottiePlayer = LottiePlayer;

  spinner = Spinner;

  toggleButton = ToggleButton;
  disclaimerBlock = Disclaimer.Block;

  pricePlansContainerBridge = PricePlansContainerBridge;

  skeleton = PricePlanContainerSkeleton;

  @service intl;
  @service store;
  @service router;
  @service errors;
  @service segment;
  @service toastFlashMessages;
  @service subscriptionManager;
  @service organizationManager;
  @service networkManager;
  @service sentry;
  @service abilities;
  @service flowLinkManager;
  @service modals;

  @tracked pricePlans = [];
  @tracked overviewPricePlans = [];
  @tracked selectedPricePlanCode = null;
  @tracked recommendedPricePlan = null;
  @tracked shouldSkipPlansStep = true; // Set true as default to prevent show plan step when skipping to confirm step
  @tracked isRedirectionPending = false;

  fetchPlansPromise = null;

  SUBSCRIPTION_RECURRENCES = SUBSCRIPTION_RECURRENCES;

  constructor() {
    super(...arguments);
    next(() => {
      this.initTask.perform().catch(ignoreCancelation);
    });
  }

  get hasModularPricing() {
    return this.organizationManager.organization.hasModularPricing;
  }

  get recurrenceToggle() {
    if (this.hasModularPricing) {
      return {
        items: [
          {
            value: this.SUBSCRIPTION_RECURRENCES.MONTHLY,
            label: this.intl.t('subscription.change.plans.monthly'),
          },
          {
            value: this.SUBSCRIPTION_RECURRENCES.ANNUAL,
            label: this.intl.t('subscription.change.plans.annual-discount'),
          },
        ],
        variant: 'default',
      };
    } else {
      return {
        items: [
          {
            value: this.SUBSCRIPTION_RECURRENCES.MONTHLY,
            label: this.intl.t('subscription.change.plans.monthly'),
          },
          {
            value: this.SUBSCRIPTION_RECURRENCES.ANNUAL,
            label: this.intl.t('subscription.change.plans.annual'),
          },
        ],
        variant: 'accent',
      };
    }
  }

  initTask = dropTask(async () => {
    try {
      let {
        plan: selectedPricePlanCode,
        recurrence: selectedPricePlanRecurrence = SUBSCRIPTION_RECURRENCES.ANNUAL,
      } = this.router.currentRoute.queryParams;

      this.fetchPlansPromise = this.hasModularPricing
        ? this.store.query('subscription-product', {
            type: 'core',
            includes: 'product_compatibilities',
            include_decoration: true,
          })
        : this.store.findAll('price-plan');

      if (selectedPricePlanCode) {
        this.isRedirectionPending = true;
        try {
          this.pricePlans = await this.fetchPlansPromise;
          await this.confirmPlanTask.perform(selectedPricePlanCode, selectedPricePlanRecurrence);
        } catch (error) {
          this.errors.handleError(error);
        }
      }
      this.isRedirectionPending = false;
      this.shouldSkipPlansStep = false;
      await this.fetchPricePlansTask.perform();

      if (this.#getIsEligibleForAddonDisclaimer()) {
        await this.fetchActiveArFeaturesTask.perform().catch(ignoreCancelation);
      }
    } catch {
      // ignore errors
    }
  });

  lineups = [
    {
      value: 'solo',
      label: this.intl.t('subscription.change.plans.solo.lineup-toggle'),
    },
    {
      value: 'teams',
      label: this.intl.t('subscription.change.plans.teams.lineup-toggle'),
    },
  ];

  get currentPricePlanCode() {
    return this.subscriptionManager.currentPricePlan.code;
  }

  get currentPricePlanRecurrence() {
    return this.subscriptionManager.currentSubscription.recurrence;
  }

  get overviewPricePlansByLineup() {
    return this.overviewPricePlans.filter(({ lineup }) => lineup === this.args.context.lineup);
  }

  get activeTrial() {
    return this.subscriptionManager.currentSubscription?.activeTrial;
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription.hasInitialTrial;
  }

  get initialTrialEndDate() {
    return this.activeTrial?.end_date;
  }

  get hasLegacyPlanDisclaimer() {
    return this.hasModularPricing && this.subscriptionManager.currentPricePlan.disabled;
  }

  get showDisclaimerForItalianOrganizations() {
    return (
      variation('feature--boolean-pricing-italian-disclaimers') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }

  get italianDisclaimerText() {
    return this.intl.t('subscription.change.bank-of-italy-disclaimer.body', {
      faqUrl: htmlSafe(
        `<a href="https://support-it.qonto.com/hc/it/articles/26999640842513-Restrizioni-in-Italia-e-miglioramento-delle-misure-di-antiriciclaggio" target="_blank" rel="noopener noreferrer"
            data-test-price-plans-italian-disclaimer-link>${this.intl.t(
              'subscription.change.bank-of-italy-disclaimer.link'
            )}</a>`
      ),
      htmlSafe: true,
    });
  }

  get showArAddonDisclaimer() {
    if (!this.#getIsEligibleForAddonDisclaimer()) {
      return false;
    }

    let { invoiceSubscriptionsStats, reminders } =
      this.fetchActiveArFeaturesTask.lastSuccessful?.value || {};

    let hasActiveReminders = reminders?.length > 0;
    let hasActiveInvoiceSubscriptions = invoiceSubscriptionsStats?.created.active > 0;

    return hasActiveInvoiceSubscriptions || hasActiveReminders;
  }

  #formatPrice(price) {
    return this.intl.formatNumber(price, {
      minimumFractionDigits: 0,
      currency: 'EUR',
      style: 'currency',
    });
  }

  getTraits(pricePlanCode, recurrence) {
    let traits = [];
    if (
      this.hasInitialTrial ||
      this.subscriptionManager.hasAvailableTrialProduct(pricePlanCode, recurrence)
    ) {
      traits.push({
        name: PRICE_PLANT_TRAITS.free_trial,
        recurrence,
        title: this.intl.t('subscription.change.plans.badges.free-trial'),
      });
    }
    if (this.isActivePlan(pricePlanCode, recurrence)) {
      traits.push({
        name: PRICE_PLANT_TRAITS.active,
        recurrence,
        title: this.intl.t('subscription.change.plans.badges.active'),
      });
    }

    if (
      this.recommendedPricePlan?.recommended_product?.code === pricePlanCode &&
      this.recommendedPricePlan?.recommended_recurrence === recurrence
    ) {
      traits.push({
        name: PRICE_PLANT_TRAITS.recommended,
        recurrence,
        title: this.intl.t('subscription.change.plans.badges.recommended'),
      });
    }
    return traits;
  }

  ctaText(pricePlanCode, recurrence) {
    let message;

    if (this.isActivePlan(pricePlanCode, recurrence)) {
      if (this.activeTrial && !this.hasInitialTrial) {
        message = this.intl.t('subscription.change.plans.summary.trial-activated');
      } else {
        message = this.intl.t('subscription.change.plans.summary.current-plan');
      }
    } else if (
      this.hasInitialTrial ||
      this.subscriptionManager.hasAvailableTrialProduct(pricePlanCode, recurrence)
    ) {
      message = this.intl.t('upsell.discover.trial.cta');
    } else if (this.isOnDifferentRecurrence(pricePlanCode, recurrence)) {
      message =
        recurrence === this.SUBSCRIPTION_RECURRENCES.ANNUAL
          ? this.intl.t('subscription.change.plans.summary.annual')
          : this.intl.t('subscription.change.plans.summary.monthly');
    } else {
      message = this.intl.t('subscription.change.plans.summary.choose-plan');
    }

    return message;
  }

  ctaOptions(pricePlanCode, recurrence) {
    return {
      text: this.ctaText(pricePlanCode, recurrence),
      isDisabled: this.isActivePlan(pricePlanCode, recurrence),
    };
  }

  getBillingRecurrence(recurrence, monthlyPrice, annualPrice) {
    return recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY
      ? monthlyPrice.billingRecurrence
      : annualPrice.billingRecurrence;
  }

  trackPlansCtaClick(code, recurrence, traits) {
    let trial_state = TRACKING_TRIAL_STATE.NONE;
    let isFreeTrial = this.isFreeTrialPlan(code, recurrence, traits);
    let isInitialTrial = this.hasInitialTrial;
    if (isFreeTrial) {
      trial_state = TRACKING_TRIAL_STATE.FREE_TRIAL;
    }
    if (isInitialTrial) {
      trial_state = TRACKING_TRIAL_STATE.INITIAL_TRIAL;
    }
    this.segment.track('plans_plan_clicked', {
      current_plan: this.currentPricePlanCode,
      target_plan: code,
      trial_state,
      end_date: this.initialTrialEndDate,
      recurrence,
      origin: undefined,
    });
  }

  get mappedPricePlansByLineup() {
    return {
      pricePlans: this.overviewPricePlansByLineup.map(
        ({
          code,
          localName,
          monthlyPrice,
          annualPrice,
          description,
          benefitGroups,
          productCompatibilities,
        }) => {
          let { recurrence: selectedPricePlanRecurrence = this.SUBSCRIPTION_RECURRENCES.ANNUAL } =
            this.router.currentRoute.queryParams;
          let recurrence = this.args.context.recurrence || selectedPricePlanRecurrence;
          let traits = this.getTraits(code, recurrence);

          let { isDisabled, text } = this.ctaOptions(code, recurrence);

          let base = {
            title: localName,
            traits,
            description,
            ctaDescription: this.getBillingRecurrence(recurrence, monthlyPrice, annualPrice),
            mainButton: {
              text,
              buttonProps: {
                // eslint-disable-next-line no-useless-computed-key
                ['data-test-overview-card-button']: true,
                variant: 'primary',
                isDisabled,
                isLoading:
                  this.selectedPricePlanTitle === localName && this.confirmPlanTask.isRunning,
                onPress: () => {
                  this.confirmPlanTask.perform(code, recurrence, traits).catch(ignoreCancelation);
                  this.trackPlansCtaClick(code, recurrence, traits);
                },
              },
            },
            benefitsGroups: benefitGroups.map(benefitGroup => ({
              title: benefitGroup.title,
              benefits: benefitGroup.benefits.map(benefit => ({
                description: benefit.description,
                isIncluded: benefit.isIncluded,
              })),
            })),
            addons: {
              title: this.intl.t('subscription.change.plans.available-add-ons.title'),
              addons: productCompatibilities.map(addon => ({
                description: codeToName(this.intl, addon.group_code),
                iconLightPath: addon.icon_light_url,
                iconDarkPath: addon.icon_dark_url,
                isAvailable: addon.is_available,
              })),
            },
          };

          if (recurrence === this.SUBSCRIPTION_RECURRENCES.MONTHLY) {
            return {
              ...base,
              recurrence,
              price: this.#formatPrice(parseFloat(monthlyPrice.perMonthAmount.value)),
              pricePeriod: monthlyPrice.period,
              descriptionCta: {
                type: 'button',
                text: monthlyPrice.annualBenefit,
                onPress: () => {
                  this.selectRecurrence(this.SUBSCRIPTION_RECURRENCES.ANNUAL);
                },
              },
            };
          }
          // recurrence === 'annual'
          return {
            ...base,
            recurrence,
            price: this.#formatPrice(parseFloat(annualPrice.perMonthAmount.value)),
            pricePeriod: annualPrice.period,
            monthlyPrice: this.#formatPrice(parseFloat(monthlyPrice.perMonthAmount.value)),
            priceDescription: annualPrice.annualBenefit,
          };
        }
      ),
    };
  }

  @action
  selectRecurrence(recurrence) {
    this.args.context.recurrence = recurrence;
    this.segment.track('recurrence_toggle_clicked', { recurrence });
  }

  @action
  selectLineup(lineup) {
    this.args.context.lineup = lineup;
    this.segment.track('plans_toggle_clicked', {
      current_plan: this.currentPricePlanCode,
      lineup,
    });
  }

  @action
  isFreeTrialPlan(pricePlanCode, rec, traits = []) {
    let traitsByRecurrence = this.#getTraitByRecurrence(traits, rec);
    return traitsByRecurrence[0]?.name === PRICE_PLANT_TRAITS.free_trial;
  }

  #getTraitByRecurrence(traits, recurrence) {
    return traits.filter(trait => trait.recurrence === recurrence);
  }

  @action
  isActiveTrialPlan(pricePlanCode, recurrence) {
    return this.activeTrial ? this.isActivePlan(pricePlanCode, recurrence) : false;
  }

  isSamePlan(pricePlanCode) {
    let { currentPricePlan } = this.subscriptionManager;

    if (this.hasModularPricing) {
      return pricePlanCode === currentPricePlan.code;
    } else {
      // The 2023 price plans don't have the same code as the previous plans (e.g. "solo_basic" vs "solo_basic_2023")
      // However we want to match them with their alternative legacy plans so we remove manually the suffix
      // All customers should have been migrated on new "2023" plans by the end of the year this should be removed in 2024
      return pricePlanCode.replace('_2023', '') === currentPricePlan.get('groupCode');
    }
  }

  @action
  isActivePlan(pricePlanCode, rec) {
    return this.isSamePlan(pricePlanCode) && this.currentPricePlanRecurrence === rec;
  }

  @action
  isOnDifferentRecurrence(pricePlanCode, rec) {
    let { recurrence } = this.subscriptionManager.currentSubscription;
    return this.isSamePlan(pricePlanCode) && recurrence !== rec;
  }

  @action
  onDiscoverAddonClick() {
    this.args.pushFlow('addon-change', 'addons');
  }

  getRecommendedPricePlan(pricePlans) {
    let pricePlan = pricePlans.find(pricePlan => {
      return pricePlan.traits.find(item => item.name === PRICE_PLANT_TRAITS.recommended);
    });
    return pricePlan;
  }

  fetchPricePlansTask = dropTask(async () => {
    this.args.context.lineup = 'solo';

    let { upsellTrigger } = this.args.context;

    if (this.hasModularPricing) {
      let recommendationPromise = Promise.resolve(null);
      // call upgrade_recommendation endpoint for modular pricing
      if (upsellTrigger) {
        recommendationPromise = this.subscriptionManager.upgradeRecommendation(upsellTrigger);
        // Add a sentry call to understand what flow uses a wrong upsell trigger value
        if (!PRICE_PLAN_FEATURES.includes(decamelize(upsellTrigger))) {
          this.sentry.captureMessage(
            `called price plan endpoint with wrong reason_to_upgade value: ${upsellTrigger}`,
            'info'
          );
        }
      }

      let [recommendedPricePlan, pricePlans] = await all([
        recommendationPromise,
        this.fetchPlansPromise,
      ]);

      pricePlans = pricePlans?.length
        ? pricePlans
        : await this.store.query('subscription-product', {
            type: 'core',
            includes: 'product_compatibilities',
          });

      this.recommendedPricePlan = recommendedPricePlan;

      this.setActiveLineup(this.recommendedPricePlan?.recommended_product);

      this.overviewPricePlans = pricePlans;
      this.pricePlans = pricePlans;
    } else {
      let currentPricePlanId = this.subscriptionManager.currentPricePlan.id;
      let legalCountry = this.organizationManager.organization.legalCountry;
      let url = `${apiBaseURL}/${apiNamespace}/price_plan_offers/${currentPricePlanId}/plan_change?legal_country=${legalCountry}&current_recurrence=${this.currentPricePlanRecurrence}`;

      if (upsellTrigger) {
        url = `${url}&reason_to_upgrade=${decamelize(upsellTrigger)}`;

        // Add a sentry call to understand what flow uses a wrong upsell trigger value
        if (!PRICE_PLAN_FEATURES.includes(decamelize(upsellTrigger))) {
          this.sentry.captureMessage(
            `called price plan endpoint with wrong reason_to_upgade value: ${upsellTrigger}`,
            'info'
          );
        }
      }

      let [response, pricePlans] = await all([
        this.networkManager.request(url),
        this.fetchPlansPromise,
      ]);

      let recommendedPricePlan = this.getRecommendedPricePlan(response.price_plans);
      this.setActiveLineup(recommendedPricePlan);

      this.overviewPricePlans = response.price_plans;
      this.pricePlans = pricePlans;
    }
  });

  fetchActiveArFeaturesTask = dropTask(async () => {
    let invoiceSubscriptionsStats = null;
    let reminders = null;

    try {
      invoiceSubscriptionsStats = await this.store.adapterFor('invoice-subscription').getStats();
      reminders = await this.store.findAll('reminders-configuration');
    } catch {
      // ignore errors
    }

    return { invoiceSubscriptionsStats, reminders };
  });

  setActiveLineup(recommendedPricePlan) {
    let { lineup } = this.router.currentRoute.queryParams;
    if (lineup) {
      this.args.context.lineup = lineup;
    } else if (recommendedPricePlan) {
      this.args.context.lineup = recommendedPricePlan.lineup;
    } else {
      let { lineup } = this.subscriptionManager.currentPricePlan;
      this.args.context.lineup = lineup;
    }
  }

  get selectedPricePlanTitle() {
    if (this.selectedPricePlanCode) {
      return this.pricePlans.find(({ code }) => this.selectedPricePlanCode === code)?.localName;
    }
  }

  confirmPlanTask = dropTask(async (code, recurrence, traits) => {
    this.selectedPricePlanCode = code;

    let pricePlan = this.pricePlans.find(item => item.code === code);

    let subscription;
    if (this.hasModularPricing) {
      subscription = this.store.createRecord('organization-subscription-new', {
        organization: this.organizationManager.organization,
        product: pricePlan,
        recurrence,
      });
    } else {
      subscription = this.store.createRecord('organization-subscription', {
        organization: this.organizationManager.organization,
        pricePlan,
        recurrence,
      });
    }

    set(this.args.context, 'subscription', subscription);
    set(
      this.args.context,
      'isFreeTrial',
      this.isFreeTrialPlan(code, recurrence, traits) && !this.hasInitialTrial
    );

    let isActiveTrial;
    if (this.hasModularPricing) {
      let { previous_product_id, previous_recurrence } = this.activeTrial || {};
      isActiveTrial =
        pricePlan.get('id') === previous_product_id && previous_recurrence === recurrence;
    } else {
      let { previous_plan_id, previous_recurrence } = this.activeTrial || {};
      isActiveTrial =
        pricePlan.get('id') === previous_plan_id && previous_recurrence === recurrence;
    }

    set(this.args.context, 'isActiveTrial', isActiveTrial);
    set(this.args.context, 'currentPricePlanCode', this.currentPricePlanCode);

    if (this.hasModularPricing) {
      try {
        let { warnings, total_estimate, extra, target_subscriptions } =
          await subscription.estimate();
        this._handleNextStep({
          isItalyError: false,
          warnings,
          targetSubscriptions: target_subscriptions,
          extraPrice: extra,
          estimatedPrice: total_estimate,
          subscription,
          hasInsufficientFunds: false,
          errors: [],
        });
      } catch ({ payload, status }) {
        if (status === 422) {
          let { errors, warnings, total_estimate, target_subscriptions, extra } = payload;
          let warningsWithoutNoRefund = warnings.filter(warning => warning.code !== 'no_refund');
          let hasInsufficientFunds = errors.some(it => it.code === 'balance_insufficient_funds');
          let blockerErrors = errors.filter(it => it.code !== 'balance_insufficient_funds');

          if (
            (!warningsWithoutNoRefund || !warningsWithoutNoRefund.length) &&
            blockerErrors.length
          ) {
            this.modals.open('subscription/blockers-modal', {
              isFullScreenModal: true,
              errors,
              subscription,
              currentPricePlanCode: this.currentPricePlanCode,
              transitionToFlow: this.args.transitionToFlow,
            });
          } else {
            this._handleNextStep({
              isItalyError: false,
              warnings,
              targetSubscriptions: target_subscriptions,
              extraPrice: extra,
              estimatedPrice: total_estimate,
              hasInsufficientFunds,
              errors: blockerErrors,
              subscription,
            });
          }
        } else {
          let errorMessage = this.intl.t('toasts.errors.server_error');
          this.toastFlashMessages.toastError(errorMessage);
        }
      }
    } else {
      try {
        let { warnings, estimated_price } = await subscription.confirmFlight();
        this._handleNextStep({
          isItalyError: false,
          warnings,
          estimatedPrice: estimated_price,
          subscription,
        });
      } catch ({ payload, status }) {
        if (status === 422) {
          let { errors, warnings, estimated_price } = payload;
          let warningsWithoutNoRefund = warnings.filter(warning => warning.code !== 'no_refund');
          let hasInsufficientFunds = errors.some(it => it.code === 'balance_insufficient_funds');
          let blockerErrors = errors.filter(it => it.code !== 'balance_insufficient_funds');

          if (
            (!warningsWithoutNoRefund || !warningsWithoutNoRefund.length) &&
            blockerErrors.length
          ) {
            this.modals.open('subscription/blockers-modal', {
              isFullScreenModal: true,
              errors,
              subscription,
              currentPricePlanCode: this.currentPricePlanCode,
            });
          } else {
            this._handleNextStep({
              isItalyError: false,
              warnings,
              estimatedPrice: estimated_price,
              hasInsufficientFunds,
              errors: blockerErrors,
              subscription,
            });
          }
        } else {
          let errorMessage = this.intl.t('toasts.errors.server_error');
          this.toastFlashMessages.toastError(errorMessage);
        }
      }
    }
  });

  #getIsEligibleForAddonDisclaimer() {
    // TODO modularPricing cleanup
    if (!this.hasModularPricing) {
      return false;
    }

    let hasArAddon = this.subscriptionManager.hasAddon(Addons.AccountsReceivable);
    let isLegacyPlan = this.subscriptionManager.currentPricePlan.disabled;
    let hasArInFeaturesInPricePlan =
      this.abilities.can('write reminders-configuration') ||
      this.abilities.can('create invoice-subscription');

    return !hasArAddon && isLegacyPlan && hasArInFeaturesInPricePlan;
  }

  _handleNextStep(args) {
    setProperties(this.args.context, args);
    this.args.transitionToNext();
  }
}
