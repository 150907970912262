/* import __COLOCATED_TEMPLATE__ from './type-selection.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { Addons } from 'qonto/constants/addons';
import { DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES } from 'qonto/constants/direct-debit-subscriptions';
import { apiBaseURL, billingNamespace } from 'qonto/constants/hosts';
import { TypeCard } from 'qonto/react/components/direct-debit-collections/type-card';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsDirectDebitCollectionsNewTypeSelectionComponent extends Component {
  @service sentry;
  @service intl;
  @service segment;
  @service subscriptionManager;
  @service toastFlashMessages;
  @service router;
  @service networkManager;

  TypeCard = TypeCard;

  get hasArAddon() {
    return this.subscriptionManager.hasAddon(Addons.AccountsReceivable);
  }

  @action
  onTypeSelect(type) {
    this.args.context.directDebitSubscription.scheduleType =
      type === 'one-off'
        ? DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.ONE_OFF
        : DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.MONTHLY;

    this.segment.track('incoming-direct-debit-flow_collection-type_selected', {
      collection_type: type,
    });

    this.args.transitionToNext();
  }

  @action
  onRecurringInvoiceSelect() {
    this.segment.track('incoming-direct-debit-flow_collection-type_selected', {
      collection_type: 'recurring-invoices',
    });

    if (this.hasArAddon) {
      this.router.transitionTo('invoice-subscriptions.new');
      return;
    }

    this.upsellRecurringInvoicesTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  }

  upsellRecurringInvoicesTask = dropTask(async () => {
    let {
      recommended_recurrence: recurrence,
      recommended_product: { code },
    } = await this.networkManager.request(
      `${apiBaseURL}/${billingNamespace}/subscriptions/upgrade_recommendation?feature_code=recurring_invoices`
    );

    this.args.context.recurrence = recurrence;
    this.args.context.addonCode = code;

    this.args.pushFlow('addon-change', 'addons');
  });
}
